import { A, cache, createAsync, useLocation, useParams } from "@solidjs/router";
import {
  Accessor,
  createEffect,
  createMemo,
  createSignal,
  For,
  Match,
  onCleanup,
  onMount,
  Show,
  Switch,
  useTransition,
} from "solid-js";
import { createStore } from "solid-js/store";
import {
  editIcon,
  editWhiteIcon,
  greenCheck,
  HubbleFullSvg,
  contactSupportIcon,
  copyIcon,
  invoiceDocumentIcon,
  brandDetailsGradientLeft,
  brandDetailsGradientRight,
  faqsGradientLeft,
  faqsGradientRight,
} from "~/assets/assets";
import { useModal } from "~/components/modal";
import { FAQs } from "~/components/pluto/faqs";
import { GiftCard } from "~/components/pluto/gift-card/gift_card";
import { CopyLinkModal } from "~/components/pluto/modals/copy_link_modal";
import { ShareAppTray } from "~/components/pluto/modals/share_app_tray";
import { Stepper } from "~/components/pluto/stepper";
import { usePluto } from "~/context/pluto_context";
import { plutoGiftFaqs } from "~/data/pluto_faqs";
import { FAQ } from "~/data/static_faqs/types";
import { clientRepo } from "~/server/apis/client_repo";
import {
  getPlutoGiftDetailsRouteData,
  PlutoGiftDetailsRouteData,
} from "~/server/data/pluto_gift_details_route_data";
import { goBack } from "~/shared_states/modal";
import { showSnackBar } from "~/shared_states/snackbar";
import { formateDateShortWithTime } from "~/utils/date";
import { toRupees } from "~/utils/number";
import { openIntercom } from "~/utils/third_party/intercom";
import { ThreeDotLoader } from "~/widgets/button";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";

const getGiftDetailsRouteData$C = cache(
  getPlutoGiftDetailsRouteData,
  "pluto_gift_details"
);

const knowPluto = [
  { content: "20L + Happy users", icon: "users" },
  { content: "500+ partner brands", icon: "lightning" },
  { content: "Zero Hassle support", icon: "headset" },
  { content: "Secure payments", icon: "shield-check" },
];

type GiftAutoFlipState = {
  autoFlip: boolean;
};

export default function PlutoGiftCard() {
  const params = useParams();
  const location = useLocation<GiftAutoFlipState>();
  const shouldAutoFlip = location.state?.autoFlip === true;

  const routeData: Accessor<PlutoGiftDetailsRouteData | undefined> =
    createAsync(() => getGiftDetailsRouteData$C(params.giftId), {
      deferStream: true,
    });

  const { plutoGifts } = usePluto();
  const { openModal } = useModal()!;

  const [latestGiftCardDetails, setLatestGiftCardDetails] = createSignal(
    plutoGifts?.newGiftCardDetails ?? routeData()?.giftDetails
  );

  onMount(async () => {
    const giftDetails = await clientRepo.getGiftById(params.giftId);
    setLatestGiftCardDetails(giftDetails);
  });

  const giftCardDetails = createMemo(() => {
    return latestGiftCardDetails() ?? routeData()?.giftDetails;
  });

  createEffect(async () => {
    if (giftCardDetails()?.paymentStatus === "PAID" && !shareLink()) {
      const response = await clientRepo.getShareableGiftLink(giftCardDetails()!.id!);
      if (response.link) {
        setShareLink(response.link);
      }
    }
  });

  const [tab, setTab] = createSignal("gift");
  const [pending, start] = useTransition();

  const updateTab = (side: string) => () => start(() => setTab(side));

  const tabs = [
    { key: "gift", label: "About Gift" },
    { key: "gift-balance", label: "About gift balance" },
    { key: "receiver-experience", label: "About receiver experience" },
  ];

  const [hasAutoFlipped, setHasAutoFlipped] = createSignal(false);
  const [moveCardDown, setMoveCardDown] = createSignal(!shouldAutoFlip);

  const handleAutoFlipComplete = async () => {
    setMoveCardDown(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setHasAutoFlipped(true);
  };

  const [shareLink, setShareLink] = createSignal("");
  const [loadingState, setLoadingState] = createSignal({
    copy: false,
    share: false,
  });

  const getShareableLink = async (action: "share" | "copy") => {
    setLoadingState((prev) => ({ ...prev, [action]: true }));
    try {
      const response = await clientRepo.getShareableGiftLink(params.giftId);
      if (response.link) {
        setShareLink(response.link);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingState((prev) => ({ ...prev, [action]: false }));
    }
  };

  const shareNowModal = async (copy?: boolean) => {
    const action = copy ? "copy" : "share";
    if (!shareLink()) {
      await getShareableLink(action);
    }

    const [isCopied, setIsCopied] = createSignal(false);

    if (copy) {
      try {
        navigator.clipboard.writeText(shareLink());
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      } catch (error) {
        showSnackBar({
          level: "error",
          message: "Failed to copy link",
        });
      }
    }

    openModal(
      () => {
        const handleShareClick = async () => {
          if (!isCopied()) {
            try {
              navigator.clipboard.writeText(shareLink());
              setIsCopied(true);
              setTimeout(() => setIsCopied(false), 2000);
            } catch (error) {
              showSnackBar({
                level: "error",
                message: "Failed to copy link",
              });
            }
          }
        };

        return (
          <div class="relative w-full p-4">
            <Show when={!copy} fallback={<CopyLinkModal />}>
              <ShareAppTray
                isCopied={isCopied}
                shareLink={shareLink}
                handleShareClick={handleShareClick}
              />
            </Show>
          </div>
        );
      },
      "dark lg:w-[400px]",
      "!bg-black !border-t !border-basePrimaryDark"
    );
  };

  const handleCopyReferenceId = async (referenceId: string) => {
    if (referenceId) {
      try {
        await navigator.clipboard.writeText(referenceId);
        showSnackBar({
          level: "success",
          message: "Reference Id copied to clipboard",
        });
      } catch (err) {
        showSnackBar({
          level: "error",
          message: "Failed to copy reference id",
        });
      }
    }
  };

  return (
    <Show when={giftCardDetails()} fallback={<DottedLoader color="#999" />}>
      <div class="no-scrollbar flex h-full w-full flex-col items-center justify-start">
        <div
          class="fixed top-0 z-10 w-full transition-opacity duration-300"
          classList={{
            "opacity-0": !(shouldAutoFlip ? hasAutoFlipped() : true),
          }}
        >
          {buildAppBar()}
        </div>
        <div class="no-scrollbar flex w-full flex-col overflow-y-scroll  md:w-[800px] lg:w-[766px]">
          <div class="mt-[70px]"></div>

          <Show
            when={giftCardDetails()?.paymentStatus === "PAID"}
            fallback={
              <div
                class="transition-opacity duration-300"
                classList={{
                  "opacity-0": !(shouldAutoFlip ? hasAutoFlipped() : true),
                }}
              >
                <Stepper activeStep={0} />
              </div>
            }
          >
            <div class="lg:hidden">{buildPaymentSuccessfulHeader()}</div>
          </Show>

          <div class="mt-4 lg:mt-8"></div>
          <div class="lg:relative lg:flex lg:gap-20">
            <div class="lg:sticky lg:top-[70px] lg:self-start">
              <div
                class="flex w-full  items-center justify-center  transition duration-300"
                classList={{
                  "translate-y-[-80px]": !moveCardDown(),
                }}
              >
                <GiftCard
                  giftDetails={() => giftCardDetails()!}
                  flipOnCard={true}
                  showActions={false}
                  autoFlip={shouldAutoFlip}
                  showNavigationDots={true}
                  showActionsBg={false}
                  onAutoFlipComplete={handleAutoFlipComplete}
                />
              </div>
              <div class="mt-10"></div>
            </div>

            <Show when={giftCardDetails()?.paymentStatus === "PAID"}>
              <div class="lg:hidden">{buildBillDetails()}</div>
            </Show>

            {buildGiftInfo()}
            <div class="mt-[97px]"></div>
          </div>
        </div>
        {buildBottomCta()}
      </div>
    </Show>
  );

  function buildAppBar() {
    function truncateName(name: string) {
      if (!name) return "";
      return name.length > 9 ? `${name.slice(0, 9)}...` : name;
    }

    return (
      <div
        class={`relative flex min-h-14 items-center justify-center bg-plutoHeaderBg  bg-cover bg-no-repeat px-4 py-2 lg:border-b lg:border-basePrimaryMedium ${shouldAutoFlip ? "animate-fade" : ""}`}
      >
        <button
          onClick={() => {
            goBack();
          }}
          class="absolute left-4 top-2 flex h-10 w-10 items-center justify-center rounded-full border border-basePrimaryDark bg-[#010101] p-2"
        >
          <PhosphorIcon
            name="arrow-left"
            fontSize={24}
            size="light"
            class="cursor-pointer text-white"
          />
        </button>
        <p class="truncate text-center text-smallBold uppercase tracking-[1.1px] text-white">
          Gift for{" "}
          {truncateName(
            giftCardDetails()?.content.text.occasion.recipientName!
          )}
        </p>
        <Show when={giftCardDetails()?.paymentStatus !== "PAID"}>
          <A
            href={`/pluto/create/${giftCardDetails()?.id}/edit`}
            class="absolute right-4 top-3 flex items-center justify-center rounded-[32px] border border-baseSecondaryDark py-1 pl-2 pr-3 lg:hidden"
          >
            <img src={editWhiteIcon} class="h-5 w-5" alt="edit icon" />
            <span class="text-f12Bold text-white">Edit gift</span>
          </A>
        </Show>
      </div>
    );
  }

  function buildPaymentSuccessfulHeader() {
    return (
      <div class="mt-4 flex flex-col items-center justify-center gap-3 lg:mb-6">
        <img class="h-10 w-10" src={greenCheck} alt="brand card" />
        <div class="text-smallBold uppercase tracking-[1.1px] text-successDark">
          Payment successful
        </div>
        <p class="w-[70%] text-center text-h3 text-white">
          Gift worth ₹{giftCardDetails()?.budget} is ready to share
        </p>
      </div>
    );
  }

  function buildBottomCta() {
    return (
      <div
        classList={{
          "opacity-0": !(shouldAutoFlip ? hasAutoFlipped() : true),
        }}
        class={`fixed bottom-0 z-50 flex w-full flex-col border-t border-basePrimaryDark bg-black  p-4  transition-opacity duration-300 lg:w-screen `}
      >
        <div class="w-full  lg:mx-auto lg:flex lg:w-[766px] lg:items-end lg:justify-between">
          <Show when={giftCardDetails()?.paymentStatus !== "PAID"}>
            <A
              href={`/pluto/create/${giftCardDetails()?.id}/edit`}
              class="hidden w-full items-center justify-start lg:flex"
            >
              <img src={editIcon} class="h-5 w-5" alt="edit icon" />
              <span class="text-buttonMedium text-white underline">
                Edit gift
              </span>
            </A>
          </Show>
          <Show
            when={giftCardDetails()?.paymentStatus === "PAID"}
            fallback={buildNotPaidState()}
          >
            {buildPaidState()}
          </Show>
        </div>
      </div>
    );

    function buildPaidState() {
      return (
        <>
          <div class="mb-3 flex w-full  justify-center lg:justify-start">
            <A
              href={giftCardDetails()?.receiversPreviewUrl ?? ""}
              target="_blank"
              class="text-buttonMedium text-white underline"
            >
              Receiver’s preview
            </A>
          </div>
          <div class="flex h-[44px] w-full   gap-3">
            <button
              onClick={() => shareNowModal(true)}
              class="flex h-full w-full  items-center justify-center rounded-[41px] border border-baseSecondaryDark bg-black px-3"
            >
              {loadingState().copy ? (
                <ThreeDotLoader color="#fff" />
              ) : (
                <p class="text-buttonMedium font-bold text-white">Copy link</p>
              )}
            </button>
            <button
              onClick={() => shareNowModal()}
              class="flex h-full w-full  items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
            >
              {loadingState().share ? (
                <ThreeDotLoader color="#000" />
              ) : (
                <p class="text-buttonMedium font-bold text-textDark">
                  Share now
                </p>
              )}
            </button>
          </div>
        </>
      );
    }

    function buildNotPaidState() {
      return (
        <div class="flex w-full gap-3 ">
          <A
            href={giftCardDetails()?.receiversPreviewUrl ?? ""}
            target="_blank"
            class="flex h-[44px] w-full items-center justify-center rounded-[41px] border border-baseSecondaryDark  px-3 text-buttonMedium"
          >
            <p class="text-nowrap text-buttonMedium text-white">
              Receiver’s preview
            </p>
          </A>
          <A
            href={`/pluto/create/${params.giftId}/budget`}
            class="flex h-[44px] w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
          >
            <p class="text-buttonMedium font-bold text-textDark">
              Next: Load money
            </p>
          </A>
        </div>
      );
    }
  }

  function buildGiftInfo() {
    const [state, setState] = createStore({
      scrollContainers: {
        brandDetails: null as HTMLDivElement | null,
        faqs: null as HTMLDivElement | null,
      },
      gradients: {
        left: {
          brandDetails: false,
          faqs: false,
        },
        right: {
          brandDetails: true,
          faqs: true,
        },
      },
      arrows: {
        brandDetails: false,
        faqs: false,
      },
    });

    const handleScroll = (type: keyof typeof state.scrollContainers) => {
      const container = state.scrollContainers[type];
      if (container) {
        setState("gradients", "left", type, container.scrollLeft > 0);
        setState(
          "gradients",
          "right",
          type,
          container.scrollLeft + container.clientWidth < container.scrollWidth
        );
      }
    };

    onMount(() => {
      const types: (keyof typeof state.scrollContainers)[] = [
        "brandDetails",
        "faqs",
      ];

      types.forEach((type) => {
        const container = state.scrollContainers[type];
        if (container) {
          container.addEventListener("scroll", () => handleScroll(type));
          handleScroll(type);
        }
      });

      onCleanup(() => {
        types.forEach((type) => {
          const container = state.scrollContainers[type];
          container?.removeEventListener("scroll", () => handleScroll(type));
        });
      });
    });

    const scrollContainer = (
      type: keyof typeof state.scrollContainers,
      direction: "left" | "right"
    ) => {
      const container = state.scrollContainers[type];
      if (container) {
        container.scrollBy({
          left: direction === "left" ? -300 : 300,
          behavior: "smooth",
        });
      }
    };

    const toggleArrows = (
      type: keyof typeof state.scrollContainers,
      show: boolean
    ) => {
      setState("arrows", type, show);
    };

    return (
      <div
        class={`transition-opacity duration-300 lg:mb-20 lg:w-[343px]`}
        classList={{
          "opacity-0": !(shouldAutoFlip ? hasAutoFlipped() : true),
        }}
      >
        <div class="hidden lg:block">
          <Show when={giftCardDetails()?.paymentStatus === "PAID"}>
            {buildPaymentSuccessfulHeader()}
          </Show>
        </div>

        <div class="hidden lg:block">
          <Show when={giftCardDetails()?.paymentStatus === "PAID"}>
            {buildBillDetails()}
          </Show>
        </div>
        <div class="mx-2 px-4 pb-4 font-jakartaSans text-smallBold uppercase tracking-[1.1px] text-textNormal lg:mx-0 lg:px-0">
          How this gift works?
        </div>
        <div class="mx-6 flex flex-col gap-5 border-t border-basePrimaryDark pb-[60px] pt-5 lg:mx-0">
          <div class="flex items-center gap-3.5">
            <div class="flex h-[52px] w-[52px] items-center justify-center rounded-full border border-[#5B5B5B] bg-[linear-gradient(270deg,_#000_8.65%,_#242424_100%)] py-[9px] pl-[18px] pr-[16px]">
              <p class="text-[24px] font-bold italic text-white">1.</p>
            </div>
            <div class="text-f16 font-medium text-white">
              This gift includes brands & experiences tailored for{" "}
              {giftCardDetails()?.content.text.occasion.recipientName}
            </div>
          </div>
          <div class="flex items-center gap-3.5">
            <div class="flex h-[52px] w-[52px] items-center justify-center rounded-full border border-[#5B5B5B] bg-[linear-gradient(270deg,_#000_8.65%,_#242424_100%)] py-[9px] pl-[18px] pr-[16px]">
              <p class="text-[24px] font-bold italic text-white">2.</p>
            </div>
            <div class="text-f16 font-medium text-white">
              <Show
                when={giftCardDetails()?.paymentStatus === "PAID"}
                fallback={
                  <>In next step load money into this gift as gift balance</>
                }
              >
                You’ve successfully loaded{" "}
                {toRupees(Number(giftCardDetails()?.budget))} as gift balance to
                this gift
              </Show>
            </div>
          </div>
          <div class="flex items-center gap-3.5">
            <div class="flex h-[52px] w-[52px] items-center justify-center rounded-full border border-[#5B5B5B] bg-[linear-gradient(270deg,_#000_8.65%,_#242424_100%)] py-[9px] pl-[18px] pr-[16px]">
              <p class="text-[24px] font-bold italic text-white">3.</p>
            </div>
            <div class="text-f16 font-medium text-white">
              {giftCardDetails()?.content.text.occasion.recipientName} can use
              the gift balance to shop on one or all brands (included in this
              gift), however they like
            </div>
          </div>
        </div>
        <div>
          <div class="mx-6 border-b border-basePrimaryDark pb-4 font-jakartaSans text-smallBold uppercase tracking-[1.1px] text-textNormal lg:mx-0">
            Brands included in this gift
          </div>
          <div
            class="relative w-full"
            onMouseEnter={() => toggleArrows("brandDetails", true)}
            onMouseLeave={() => toggleArrows("brandDetails", false)}
          >
            <div class="hidden lg:block">
              <Show when={state.gradients.left.brandDetails}>
                <div>
                  <img
                    src={brandDetailsGradientLeft}
                    alt=""
                    class="h-full lg:absolute lg:left-0 lg:z-[9]"
                  />
                  <Show when={state.arrows.brandDetails}>
                    <div
                      onClick={() => scrollContainer("brandDetails", "left")}
                      class="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border border-basePrimaryDark bg-[#010101] p-1 lg:absolute lg:left-0 lg:top-[38px] lg:z-20"
                    >
                      <PhosphorIcon
                        name="caret-left"
                        size="light"
                        fontSize={20}
                        class="text-white"
                      />
                    </div>
                  </Show>
                </div>
              </Show>
            </div>
            <div class="hidden lg:block">
              <Show when={state.gradients.right.brandDetails}>
                <div>
                  <img
                    src={brandDetailsGradientRight}
                    alt=""
                    class="h-full lg:absolute lg:right-0 lg:z-[9]"
                  />
                  <Show when={state.arrows.brandDetails}>
                    <div
                      onClick={() => scrollContainer("brandDetails", "right")}
                      class="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border border-basePrimaryDark bg-[#010101] p-1 lg:absolute lg:right-0 lg:top-[38px] lg:z-20"
                    >
                      <PhosphorIcon
                        name="caret-right"
                        size="light"
                        fontSize={20}
                        class="text-white"
                      />
                    </div>
                  </Show>
                </div>
              </Show>
            </div>
            <div
              ref={(el) => setState("scrollContainers", "brandDetails", el)}
              class="no-scrollbar mb-[60px] ml-2 mt-[25px] flex w-[98%] gap-x-3 overflow-x-scroll pl-4 pr-4 lg:ml-0 lg:mr-2 lg:pl-0 lg:pr-2"
            >
              <For each={giftCardDetails()?.content.text.activitySlots}>
                {(activity, index) => {
                  return (
                    <div class="flex min-h-[104px] min-w-[307px] gap-3 rounded-xl bg-[#1A1A1A] p-4">
                      <img
                        src={
                          giftCardDetails()?.brandDetails.find(
                            (brand) =>
                              brand.brandKey ===
                              activity.activities[
                                Number(
                                  giftCardDetails()?.content.selection
                                    .activities[index() + 1]
                                ) - 1
                              ].brandKey
                          )?.logoUrl
                        }
                        alt=""
                        class="aspect-square h-[72px] w-[72px] rounded-full object-cover"
                      />
                      <div class="flex flex-col justify-center gap-1.5">
                        <p class="font-jakartaSans text-f16 font-medium text-white">
                          {
                            giftCardDetails()?.brandDetails.find(
                              (brand) =>
                                brand.brandKey ===
                                activity.activities[
                                  Number(
                                    giftCardDetails()?.content.selection
                                      .activities[index() + 1]
                                  ) - 1
                                ].brandKey
                            )?.name
                          }
                        </p>
                        <p class={`text-medium text-textNormal`}>
                          {
                            activity.activities[
                              Number(
                                giftCardDetails()?.content.selection.activities[
                                  index() + 1
                                ]
                              ) - 1
                            ]?.description
                          }
                        </p>
                      </div>
                    </div>
                  );
                }}
              </For>
            </div>
          </div>
          <div class="mx-2 px-4  font-jakartaSans text-smallBold uppercase tracking-[1.1px] text-textNormal lg:mx-0 lg:px-0">
            FAQs
          </div>
          <div
            class="relative w-full"
            onMouseEnter={() => toggleArrows("faqs", true)}
            onMouseLeave={() => toggleArrows("faqs", false)}
          >
            <div class="hidden lg:block">
              <Show when={state.gradients.left.faqs}>
                <div>
                  <img
                    src={faqsGradientLeft}
                    alt=""
                    class="h-full lg:absolute lg:left-0 lg:z-[9]"
                  />
                  <Show when={state.arrows.faqs}>
                    <div
                      onClick={() => scrollContainer("faqs", "left")}
                      class="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border border-basePrimaryDark bg-[#010101] p-1 lg:absolute lg:left-0 lg:top-0.5 lg:z-20"
                    >
                      <PhosphorIcon
                        name="caret-left"
                        size="light"
                        fontSize={20}
                        class="text-white"
                      />
                    </div>
                  </Show>
                </div>
              </Show>
            </div>
            <div class="hidden lg:block">
              <Show when={state.gradients.right.faqs}>
                <div>
                  <img
                    src={faqsGradientRight}
                    alt=""
                    class="h-full lg:absolute lg:right-0 lg:z-[9]"
                  />
                  <Show when={state.arrows.faqs}>
                    <div
                      onClick={() => scrollContainer("faqs", "right")}
                      class="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border border-basePrimaryDark bg-[#010101] p-1 lg:absolute lg:right-0 lg:top-0.5 lg:z-20"
                    >
                      <PhosphorIcon
                        name="caret-right"
                        size="light"
                        fontSize={20}
                        class="text-white"
                      />
                    </div>
                  </Show>
                </div>
              </Show>
            </div>
            <nav
              ref={(el) => setState("scrollContainers", "faqs", el)}
              class="no-scrollbar mb-5 ml-2 mt-4 flex w-[98%] gap-x-3 overflow-x-scroll pl-4 pr-4 lg:ml-0 lg:mr-2 lg:pl-0 lg:pr-2"
            >
              <For each={tabs}>
                {(item) => (
                  <button
                    type="button"
                    class="flex h-8 w-fit cursor-pointer items-center justify-center gap-1 text-nowrap rounded-lg border border-basePrimaryMedium px-3 py-2.5 text-mediumBold transition-colors duration-300"
                    classList={{
                      "text-textDark bg-white ": tab() === item.key,
                      "text-white bg-transparent": tab() !== item.key,
                    }}
                    onClick={updateTab(item.key)}
                  >
                    <Show when={tab() === item.key}>
                      <PhosphorIcon
                        name="check"
                        fontSize={16}
                        size="bold"
                        class="text-textDark"
                      />
                    </Show>
                    <span>{item.label}</span>
                  </button>
                )}
              </For>
            </nav>
          </div>
          <div
            class={`mx-2 px-4 lg:mx-0 lg:mb-8 lg:px-0 ${giftCardDetails()?.paymentStatus !== "PAID" ? "pb-6 lg:pb-0" : "pb-14 lg:mb-4 lg:pb-0"}`}
            classList={{ pending: pending() }}
          >
            <Switch>
              <Match when={tab() === "gift"}>
                <FAQs faqs={plutoGiftFaqs.gift} />
              </Match>
              <Match when={tab() === "gift-balance"}>
                <FAQs faqs={plutoGiftFaqs.giftBalance} />
              </Match>
              <Match when={tab() === "receiver-experience"}>
                <FAQs faqs={plutoGiftFaqs.receiverExperience} />
              </Match>
            </Switch>
          </div>
          <Show when={giftCardDetails()?.paymentStatus !== "PAID"}>
            <div class="mx-4 mb-5 rounded-2xl border border-basePrimaryDark bg-[#0C1410] p-4 lg:mx-0">
              <div class="flex flex-col gap-1">
                <p class="text-smallBold uppercase tracking-[1.1px] text-textNormal">
                  Get to know Pluto
                </p>
                <div class="flex items-center gap-1 pb-4 text-medium text-white ">
                  <span>Pluto is a product by</span>{" "}
                  <HubbleFullSvg class="mb-[1px] h-3" fill="#fff" />
                  <span> with:</span>
                </div>
                <div class="grid grid-cols-2 justify-between gap-2 border-t border-basePrimaryDark pt-4">
                  <For each={knowPluto}>
                    {(data) => (
                      <div class="flex items-center justify-start gap-1">
                        <PhosphorIcon
                          fontSize={20}
                          name={data.icon}
                          size="fill"
                          class="text-successDark"
                        />
                        <p class="text-small text-baseTertiaryDark">
                          {data.content}
                        </p>
                      </div>
                    )}
                  </For>
                </div>
              </div>
            </div>
          </Show>
        </div>
      </div>
    );
  }

  function buildBillDetails() {
    return (
      <div
        class={`mx-4 mb-10 mt-10 transition-opacity duration-300 lg:mx-0`}
        classList={{
          "opacity-0": !(shouldAutoFlip ? hasAutoFlipped() : true),
        }}
      >
        <div class="mx-2 flex flex-col gap-3">
          <div class="flex flex-col gap-3 rounded-2xl bg-[#1A1A1A] p-4">
            <p class="text-bold text-white">Bill details</p>
            <div class="flex items-center justify-between border-y border-baseSecondaryDark py-3">
              <p class="text-mediumBold text-white">Gift worth</p>
              <p class="text-mediumBold text-white">
                {toRupees(giftCardDetails()?.budget!)}
              </p>
            </div>
            <div class="flex items-end justify-between">
              <div>
                <p class="text-mediumBold text-white">You paid</p>
                <p class="text-f12 text-textNormal">
                  Via {giftCardDetails()?.paymentDetails.paymentMode}
                </p>
              </div>
              <p class="text-bold text-white">
                {toRupees(giftCardDetails()?.budget!)}
              </p>
            </div>
          </div>
          <div class="flex flex-col gap-3 rounded-2xl bg-[#1A1A1A] p-4">
            <p class="border-b border-baseSecondaryDark pb-4 text-bold text-white">
              Order details
            </p>
            <div class="flex items-center justify-between">
              <p class="text-mediumBold text-white">Reference ID</p>
              <div class="flex items-center gap-2">
                <p class="text-medium text-textNormal">
                  {(giftCardDetails()?.paymentDetails.referenceId?.length ??
                  0 > 10)
                    ? `${giftCardDetails()?.paymentDetails.referenceId.slice(0, 10)}...`
                    : giftCardDetails()?.paymentDetails.referenceId}
                </p>
                <img
                  src={copyIcon}
                  onClick={() =>
                    handleCopyReferenceId(
                      giftCardDetails()?.paymentDetails.referenceId!
                    )
                  }
                  class="cursor-pointer"
                  alt="copy icon"
                />
              </div>
            </div>

            <div class="flex items-center justify-between">
              <p class="text-mediumBold text-white">Purchase date</p>
              <p class="text-medium text-textNormal">
                {formateDateShortWithTime(
                  new Date(`${giftCardDetails()?.paymentDetails.paymentDate!}Z`)
                )}
              </p>
            </div>
          </div>
          <div class="flex flex-col gap-4 rounded-2xl bg-[#1A1A1A] p-4">
            <A
              href={`/pluto/create/${giftCardDetails()?.id}/invoice`}
              target="_blank"
              class="flex cursor-pointer items-center gap-1.5 border-b border-baseSecondaryDark pb-4 "
            >
              <img src={invoiceDocumentIcon} alt="View invoice" />
              <p class="text-mediumBold text-white">View invoice</p>
            </A>
            <div
              class="flex cursor-pointer items-center gap-1.5"
              onClick={() => {
                openIntercom();
              }}
            >
              <img src={contactSupportIcon} alt="View invoice" />
              <p class="text-mediumBold text-white">Contact support</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
